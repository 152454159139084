import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Layout, Seo, FullBleed } from 'components';
import { Link } from 'gatsby';

import BottleAndBoxImg from 'images/bottle-and-box.png';
import CheckMarkImg from 'images/check-mark.png';

// import page style
import './index.scss';

const IndexPage = () => {
  /**
   * Remove all the HTML and CSS contents from this page to use your own.
   * All the contents in this page is just for the demonstration purpose.
   * You may use React Flexible Grid or any other grid of your choice
   * The following styles should be moved into the page specific scss file.
   */

  const referencesHtml = (
    <p>
      <span className="bold">References: 1.</span> U.S. Food and Drug
      Administration. Product quality review.
      https://www.accessdata.fda.gov/drugsatfda_docs/nda/2020/213994
      Orig1s000ChemR.pdf.&nbsp;Accessed March 8, 2022.&nbsp;
      <span className="bold">2.</span> American Society of Health-System
      Pharmacists. ASHP guidelines on preventing medication errors in hospitals.
      https://www.ashp.org/-/media/assets/policy-guidelines/docs/guidelines/preventing-medication-errors-hospitals.ashx.
      Accessed October 20, 2021.
    </p>
  );

  return (
    <Layout
      indication="patient"
      className="home"
      referencesHtml={referencesHtml}
    >
      <Seo pageTitle="Home" />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          {/** Actuall content goes here */}
          <FullBleed className="container-full-bleed full-bleed-hero">
            <div className="container-index-hero">
              <Row>
                <Col xs={12} md={6} lg={8}>
                  <div className="container-hero-bottle-and-box mobile-only">
                    <img
                      className="hero-bottle-and-box"
                      src={BottleAndBoxImg}
                    />
                    <p>Not actual size.</p>
                  </div>

                  <div className="hero-text-and-button">
                    <h1>Ephedrine Sulfate Injection</h1>
                    <p className="subhead bold">Pre-Mixed Vials</p>
                    <p className="dosages">
                      50 mg/10 mL
                      <br />
                      (5 mg/mL)
                    </p>
                    <p className="text-fda-approved bold">
                      <nobr>FDA Approved</nobr> &bull;{' '}
                      <nobr>Manufacturer Prepared</nobr>
                    </p>

                    <button className="button-workflow-scenarios">
                      <Link to="/workflow-scenarios">Workflow Scenarios</Link>
                    </button>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={4} className="desktop-only">
                  <div className="container-hero-bottle-and-box">
                    <img
                      className="hero-bottle-and-box"
                      src={BottleAndBoxImg}
                    />
                    <p>Not actual size.</p>
                  </div>
                </Col>
              </Row>
            </div>
          </FullBleed>
          <FullBleed className="container-full-bleed full-bleed-now-available">
            <div className="container-now-available gutter-all">
              <Row>
                <Col xs={12} md={12} className="col-now-available">
                  <h1>Now Available</h1>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <div className="container-text-now-available-left-column">
                    <p>
                      Ephedrine Sulfate Injection is an alpha- and
                      beta-adrenergic agonist and a norepinephrine-releasing
                      agent that is indicated for the treatment of clinically
                      important hypotension occurring in the setting of
                      anesthesia.
                    </p>
                    <p>
                      Ephedrine Sulfate Injection is available as a single-dose,
                      10 mL vial that contains <nobr>50 mg/10 mL</nobr>{' '}
                      ephedrine sulfate, equivalent to 38 mg ephedrine base.
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <table className="now-available-checklist">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            className="now-available-check-mark"
                            src={CheckMarkImg}
                          />
                          &nbsp;
                        </td>
                        <td>
                          Ready-to-use formulation does not require compounding,
                          diluting, or mixing, which may reduce waste and chance
                          of preparation error
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            className="now-available-check-mark"
                            src={CheckMarkImg}
                          />
                          &nbsp;
                        </td>
                        <td>
                          Two-year shelf life<sup>1*</sup> may offer enhanced
                          inventory management
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            className="now-available-check-mark"
                            src={CheckMarkImg}
                          />
                          &nbsp;
                        </td>
                        <td>Available through your wholesaler</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            className="now-available-check-mark"
                            src={CheckMarkImg}
                          />
                          &nbsp;
                        </td>
                        <td>
                          Compatible with most automated dispensing machines
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            className="now-available-check-mark"
                            src={CheckMarkImg}
                          />
                          &nbsp;
                        </td>
                        <td>
                          Aligns with medication strategies of{' '}
                          <span className="italic">
                            ASHP Guidelines on Preventing Medication Errors in
                            Hospitals
                          </span>
                          <sup className="shift-right">2</sup>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} className="col-now-available">
                  <button className="button-workflow-scenarios">
                    <Link to="/workflow-scenarios">Workflow Scenarios</Link>
                  </button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <table className="footnote-home">
                    <tbody>
                      <tr>
                        <td className="footnote-text">*</td>
                        <td className="footnote-text">
                          When stored at the recommended storage condition of
                          20–25°C (68–77°F), away from light.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </FullBleed>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
